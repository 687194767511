<template>
  <div>
    <v-row no-gutters class="mt-12 mb-2">
      <v-col cols="12" class="text-center">
        <span class="goto"> Altri servizi </span>
      </v-col>
    </v-row>

    <span class="white--text d-flex justify-center">
      <!-- {{ show_links }} -->
      <div v-for="(service, index) in show_links" :key="index">
        <v-btn
          v-if="service.link"
          :to="{ name: service.link }"
          text
          small
          color="white"
        >
          <span class="text-center" v-html="service.title"></span>
        </v-btn>
      </div>
    </span>

    <!-- <v-row class="mt-2" no-gutters>
      <v-col cols="12" md="4" offset-md="4" class="text-center">
        <v-btn block color="white" small text :to="{ name: 'OurServices' }"
          >Torna ai servizi offerti</v-btn
        >
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { services } from "@/views/OurServices";

export default {
  props: {},
  components: {},
  data: () => ({
    services: services,
  }),
  methods: {},
  mounted() {
    console.log(this.services);
  },
  created() {},
  computed: {
    show_links() {
      return this.services.filter((service) => {
        if (this.$route.name != service.link) return true;
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
.goto {
  font-weight: bold;
  font-size: 0.8rem;
}
</style>