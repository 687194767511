<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : ''"
    style="margin-bottom: 100px; margin-top: 100px"
  >
    <v-row class="mt-12" align="center" justify="center">
      <v-col cols="12" md="8" lg="6" xl="4">
        <v-card color="transparent" flat>
          <v-card-text class="white--text">
            <!-- <v-col cols="12" class="text-center">About me</v-col> -->
            <v-row>
              <v-col cols="12" md="4" class="d-flex justify-center">
                <v-img
                  :max-width="$vuetify.breakpoint.smAndDown ? '200px' : '250px'"
                  :src="require('@/assets/imgs/avm_profile.png')"
                  alt="Foto profilo Alessandro Vittorio Mendolia"
                  class="ma-0 pa-0"
                  contain
                ></v-img>
              </v-col>

              <v-col
                cols="12"
                md="8"
                class="d-flex flex-column justify-end"
                v-bind:class="
                  $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'
                "
                ><h1
                  style="line-height: normal"
                  v-bind:class="
                    $vuetify.breakpoint.smAndDown ? '' : 'mb-12 ml-4'
                  "
                >
                  Alessandro Vittorio Mendolia
                </h1>
                <!-- <hr /> -->
                <span
                  class="mt-4"
                  v-bind:class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'"
                >
                  <strong>
                    Mi occupo di tecnologia, sistemi informatici e innovazione
                    digitale da oltre 20 anni
                  </strong>
                </span>
                <span
                  class="mt-4"
                  v-bind:class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'"
                >
                  Cresciuto all'interno di grandi realtà fortemente strutturate
                  e informatizzate ho acquisito competenze in diversi ambiti del
                  Information Tecnology, della sicurezza e dello sviluppo
                  software.
                </span>

                <div
                  v-bind:class="
                    $vuetify.breakpoint.smAndDown
                      ? 'text-center my-3'
                      : 'text-right'
                  "
                >
                  <v-btn
                    fab
                    href="https://www.linkedin.com/in/alessandromendolia/"
                    target="_blank"
                    small
                  >
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12" class="text-center pb-0">
                <hr />
                <span>
                  Aperto ai rapporti interpersonali, curioso e affamato di
                  conoscenza, sono naturalmente attratto dalle sfide nella
                  risoluzione di problemi e dall'ottimizzazione dei processi con
                  un unico grande obiettivo:
                  <br />
                  <span class="satisfaction"
                    >la soddisfazione del cliente
                  </span>
                </span>
              </v-col>

              <v-col cols="12" class="py-0">
                <hr />
              </v-col>

              <v-col cols="12" class="text-center pt-12 pb-0">
                <v-icon color="white" style="font-size: 4rem" x-large
                  >mdi-account-multiple-plus</v-icon
                >
              </v-col>

              <v-col cols="12" class="text-center pb-12">
                <!-- <span
                  >A necessità collaboro e mi avvalgo di consulenze di
                  professionisti con competenze verticalizzate in vari ambiti
                  per offrire servizi di qualità</span
                > -->
                <h2>Team dinamico</h2>
                <div
                  style="display: flex; width: 100%; justify-content: center"
                  class="text-center"
                >
                  <p style="max-width: 300px">
                    Per offrire sempre servizi di alto livello ed elevata
                    qualità mi avvalgo di collaborazioni e consulenze da parte
                    di professionisti dalle elevate competenze verticalizzate.
                  </p>
                </div>
              </v-col>

              <v-col cols="12" class="py-0">
                <hr />
              </v-col>

              <v-col cols="12" class="text-center">
                <h2>Dove mi trovo?</h2></v-col
              >

              <v-col cols="12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2850.7858334638704!2d7.8212912508002255!3d44.396517911962405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cd6345f143dadb%3A0x7a0a6efa70abc173!2sAVMSquare%20Informatica!5e0!3m2!1sit!2sit!4v1647351234495!5m2!1sit!2sit"
                  width="100%"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </v-col>

              <v-col cols="12" class="text-center">
                Via Torino 18 - Villanova Mondovì <br />
                12089 - Cuneo
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div style="height: 100px"></div>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import { EventBus } from "@/main";
import OtherServicesLinks from "@/components/otherServicesLinks";

export default {
  metaInfo: {
    title: "Chi sono?",
    meta: [
      {
        name: "description",
        content:
          "Alessandro Vittorio Mendolia, da oltre 20 anni mi occupo di sistemi tecnologici e innovazione digitale",
      },
    ],
  },

  props: {},
  components: {
    OtherServicesLinks,
  },
  data: () => ({
    bgColor: "#0000002e",
    audio: 1,
  }),
  methods: {
    playHoverSound(what) {
      console.log("play hover sound", what);
      if (this.audio == 1) {
        var sound = new Howl({
          src: require("@/assets/audio/button-35.mp3"),
          volume: 0.1,
        });
        sound.play();
      }
    },
    playClickSound(what) {
      //   console.log("playClickSound " + this.audio, what);
      //   this.what_show = what;
      //   if (this.audio == 1) {
      //     var sound = new Howl({
      //       src: require("@/assets/audio/button-17.mp3"),
      //       volume: 0.1,
      //     });
      //     sound.play();
      //   }
    },

    setHoverBgColor(what) {
      //   this.playHoverSound(what);
      //   console.log("setHoverBgColor");
      this.bgColor = "#00000080";
    },
    resetHoverBgColor() {
      //   console.log("resetHoverBgColor");
      this.bgColor = "#0000002e";
    },
  },
  mounted() {
    EventBus.$on("set_audio", (value) => {
      this.audio = value;
      console.log("event set_audio: " + this.audio);
    });
  },
  created() {},
  computed: {
    header_img_width() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "75px";
      } else {
        return "110px";
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.satisfaction {
  font-weight: bold;
}

.satisfaction {
  text-shadow: 0px 0px 10px white;
  letter-spacing: 0px;
  -webkit-animation: glowing 1s ease-in-out infinite alternate;
  -moz-animation: glowing 1s ease-in-out infinite alternate;
  animation: glowing 2s ease-in-out infinite alternate;
}
@-webkit-keyframes glowing {
  from {
    text-shadow: 0px 0px 0px white;
  }
  to {
    text-shadow: 0px 0px 2px white;
    letter-spacing: 0.5px;
  }
}
</style>